@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
*,
*::before,
*::after {
  box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}


ul[role='list'],
ol[role='list'] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {

	color: #222;
	
	color: var(--dark);
	
	font-family: Verdana;
	
	line-height: 1.5;
	
	text-rendering: optimizeSpeed;
	
	}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img,
picture {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select {
  font: inherit;
}

/* GLOBAL STYLES */
:root {
	--blue: #852821;
	--grey: #F5F5F5;
	--grey-d-1: #EEE;
	--grey-d-2: #DDD;
	--grey-d-3: #888;
	--white: #FFF;
	--dark: #222;
}
/* GLOBAL STYLES */

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	position: relative;

  }
  
.chatbox-message-wrapper {

/* border-radius: .5rem; */

/* box-shadow: .5rem .5rem 2rem rgba(0,0,0,.1); */

/* left: 50%; */

/* margin-left: -400px; */

/* margin-top: -325px; */

overflow: hidden;

position: fixed;

/* top: 50%; */

-webkit-transform: scale(1);

transform: scale(1);

width: 100%;

}


.chatbox-message-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: var(--white);
	padding: .75rem 1.5rem;
}
.chatbox-message-profile {
	display: flex;
	align-items: center;
	grid-gap: 1.0rem;
}
.chatbox-message-image {
	width: 4.5rem;
	height: 4.5rem;
	object-fit: cover;
	border-radius: 50%;
}

.chatbox-message-name {
	
	font-size: 2.0rem;
	font-weight: 600;
}
.chatbox-message-status {
	font-size: 1.0rem;
	color: var(--grey-d-3);
	
}



.chatbox-message-content {

	grid-row-gap: 1rem;
	
	background: #f5f5f5;
	
	background: var(--grey);
	
	display: flex;
	
	flex-direction: column;
	
	height: 354px;
	
	max-height: 354px;
	
	overflow-y: auto;
	
	padding: 1.5rem;
	
	}
	.chatbox-message-item {

		font-size: 1.0rem;
		
		}
.chatbox-message-item.sent {
	align-self: flex-end;
	background: #eb1100;
	color: var(--white);
	border-radius: .75rem 0 .75rem .75rem;
	padding: 5px 10px;
	
}
.chatbox-message-item.received {
	background: var(--white);
	border-radius: 0 .75rem .75rem .75rem;
	box-shadow: .25rem .25rem 1.5rem rgba(0, 0, 0, .05);
	padding: 10px;
	
}
.chatbox-message-item.agb {
	background: var(--white);
	border-radius: 0 .75rem .75rem .75rem;
	box-shadow: .25rem .25rem 1.5rem rgba(0, 0, 0, .05);
	padding: 10px;
}

.chatbox-message-item.loading {
	
	background: transparent;
	color: var(black);
	border-radius: .75rem 0 .75rem .75rem;
}


.chatbox-message-item-time {
	float: right;
	font-size: .75rem;
	margin-top: .5rem;
	display: inline-block;
}

.chatbox-message-bottom {
	background: var(--white);
	padding: .75rem 1.5rem;
}
.chatbox-message-form {
	display: flex;
	align-items: center;
	background: var(--grey);
	border-radius: .5rem;
	padding: .5rem .25rem;
	font-size: 1.1rem;

}
.chatbox-message-input {
	height: auto;          
  max-height: 100px;
	background: transparent;
	outline: none;
	border: none;
	resize: none;
	scrollbar-width: none;
	width: 67%;

}
.chatbox-message-input::-webkit-scrollbar {
	display: none;
}
.chatbox-message-submit {

	right: 30px;
	padding:5px;
	border-radius:10px;
	background:#eb1100;
	
	border: none;
	
	color:#ffffff;
	position: absolute;
	cursor: pointer;
	
	font-size: 1.25rem;
	
	font-size: 1.5rem;
	
	outline: none;
	
	}
.chatbox-message-no-message {
	font-size: 1rem;
	font-weight: 600;
	text-align: center;
}


.loading-dots {
	background: transparent;
	display: flex;
	justify-content: left;
	align-items: left;

  }
  
  .loading-dots .dot {
	animation: pulse 2s infinite;
	margin: 0 0px;
  }
  
  @keyframes pulse {
	0% {
	  transform: scale(0.8);
	}
	50% {
	  transform: scale(1);
	}
	100% {
	  transform: scale(0.8);
	}
  }
  
  .pulsating-circle {
	width: 50px;
	height: 50px;
	border-radius: 550%;
	animation: pulse 2s infinite;
  }
  
  



  .radio-group {
	display: flex;
	flex-direction: column;
	align-items: flex-start; 
	margin-top: 10px;
  }
  
  .radio-group label {
	display: flex;
	align-items: center;
	margin-bottom: 5px;
	cursor: pointer;
  }
  
  .radio-group input[type="radio"] {
	width: 25px;
	height: 25px;
	margin-right: 5px;
  }

  
  
  
  
  